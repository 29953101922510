import { COMMON_BOX_SHADOW } from "@styles/GlobalStyle";
import breakpoints from "@styles/breakpoints";
import styled, { css } from "styled-components";

const DropDown = styled.div<{ open: boolean }>`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 0;
  width: 0;
  opacity: 0;
  display: none;
  position: absolute;
  height: 80px;
  right: 0px;
  border: 1px solid #e4e4e7;
  -webkit-box-shadow: ${COMMON_BOX_SHADOW};
  box-shadow: ${COMMON_BOX_SHADOW};
  border-radius: 10px;
  background-color: white;
  z-index: 100;
  ${({ open }) =>
    open &&
    css`
      display: flex;
      height: max-content;
      width: 240px;
      opacity: 1;
    `}
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0px 0px 8px 0px;
  @media ${breakpoints.tablet} {
    margin: 0;
  }
`;

export const ProfileStyles = { DropDown, Container };
