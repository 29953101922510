import React, { ForwardedRef, forwardRef, useEffect, useRef } from "react";
import { setCredentials } from "@features/auth/auth-slice";
import {
  clearProfile,
  selectProfile,
  setAvatar,
  setAvatarError,
  setFirstAttempt,
} from "@features/user/user-slice";
import { useAppDispatch, useAppSelector } from "@helpers/hooks/redux";
import {
  Flex,
  Text,
  UnselectedText,
  IconComponent,
  errorNotify,
  Button,
} from "@new-ui-kit";

import { profilePopupStyles } from "./styles";
import { ProfilePopupPropsInterface as Props } from "./types";
import LogoutIcon from "@assets/logout.svg";
import SettingsIcon from "@assets/sidebarIcons/settings.svg";
import { modalStyles } from "src/ui-kit/Modal/styles";
import { SidebarStyles } from "@components/Sidebar/styles";

import closeIcon from "@assets/close.svg";
import burgerMenu from "@assets/sidebarIcons/menu.svg";
import { addProxy } from "@utils";
import MainLogo from "@components/Sidebar/components/MainLogo";
import { useMeQuery } from "@features/user/user-api-slice";

import {
  useDesktopMediaQuery,
  useMobileMediaQuery,
} from "@helpers/hooks/apaptive";
import { useOutsideClick } from "@helpers/hooks/useOutsideClick";
import useSafeRouter from "@helpers/hooks/useSafeRouter";

const { StyledProfilePopover, Avatar, DefaultAvatar, NameText } =
  profilePopupStyles;
const { SidebarModalWrapper } = modalStyles;
const { StyledSidebar, StyledMenu, StyledSidebartabletHeader } = SidebarStyles;

const ProfilePopup = forwardRef(
  ({ openBurgerMenu, ...props }: Props, ref: ForwardedRef<HTMLElement>) => {
    const { replace } = useSafeRouter();
    const profileRef = useRef(null);
    const { refetch: refetchUserData } = useMeQuery({});

    const dispatch = useAppDispatch();
    const profile = useAppSelector((state) => selectProfile(state));
    const isDesktop = useDesktopMediaQuery();
    const isMobile = useMobileMediaQuery();
    const hadFirstName = !!profile?.first_name;
    const hadLastName = !!profile?.last_name;
    const hadEmail = !!profile?.email;
    const fullName = `${profile?.first_name}${
      hadLastName ? ` ${profile?.last_name}` : ""
    }`;
    const firstNameFirstWord = !!profile?.first_name
      ? profile?.first_name[0]
      : "";
    const avatarSize = isDesktop ? 72 : 28;
    const lastNameFirstWord = !!profile?.last_name ? profile?.last_name[0] : "";
    const avatarWords = `${firstNameFirstWord || ""}${lastNameFirstWord || ""}`;
    const hadAvatar = profile?.avatar || (!profile?.avatar && !!avatarWords);

    useEffect(() => {
      if (profile?.hasAvatarError && profile?.isFirstAttemptForAvatarRefetch) {
        refetchUserData()
          .unwrap()
          .then(async (userData) => {
            try {
              const response = await fetch(addProxy(userData.avatar!));
              if (response.status === 200) {
              } else {
                errorNotify(
                  "Ошибка загрузки данных",
                  "При загрузке фото профиля произошла ошибка на стороне сервера. Мы уже разбираемся с этой проблемой и постараемся устранить её в ближайшее время.",
                  "outerSourseImageError"
                );
              }
            } catch (error) {
              errorNotify(
                "Ошибка загрузки данных",
                "При загрузке фото профиля произошла ошибка на стороне сервера. Мы уже разбираемся с этой проблемой и постараемся устранить её в ближайшее время.",
                "outerSourseImageError"
              );
            } finally {
              dispatch(setAvatarError(false));
            }
          })
          .finally(() => {
            dispatch(setFirstAttempt(false));
          });
      }
    }, [dispatch, profile?.hasAvatarError, refetchUserData]);

    useOutsideClick(profileRef, () => {
      if (!isMobile) props.onClose();
    });

    const exit = () => {
      dispatch(
        setCredentials({
          refresh: "",
          access: "",
          role: null,
        })
      );
      dispatch(clearProfile());
    };
    const goToSettings = () => replace("/settings");

    const profileMenuList = () => {
      return (
        <>
          <Button
            pattern="dropdown"
            fullWidth
            icon={SettingsIcon}
            onClick={goToSettings}
            isBorderTop={isDesktop}
            nowrap
          >
            Настройки профиля
          </Button>
          <Button
            pattern="dropdown"
            fullWidth
            icon={LogoutIcon}
            onClick={exit}
            isBorderTop={isDesktop}
            nowrap
          >
            Выйти
          </Button>
        </>
      );
    };

    if (isDesktop) {
      return (
        <StyledProfilePopover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          height={hadAvatar ? "290px" : "215px"}
          {...props}
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="8px"
            width="100%"
            margin="32px 0 32px 0"
          >
            {profile?.croppedAvatar ? (
              <Avatar
                alt="Аватар"
                src={profile?.croppedAvatar}
                width={avatarSize}
                height={avatarSize}
              />
            ) : !!avatarWords ? (
              <DefaultAvatar
                width={avatarSize + "px"}
                height={avatarSize + "px"}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <UnselectedText pattern="headline2" fontSize={24} weight={400}>
                  {avatarWords}
                </UnselectedText>
              </DefaultAvatar>
            ) : (
              <></>
            )}
            {(hadFirstName || hadLastName) && (
              <NameText pattern="headline4">{fullName}</NameText>
            )}
            {hadEmail && (
              <Text pattern="caption1" color="dark_gray">
                {profile?.email}
              </Text>
            )}
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            width="100%"
            height="96px"
            minHeight="96px"
            maxHeight="96px"
          >
            {profileMenuList()}
          </Flex>
        </StyledProfilePopover>
      );
    } else {
      return (
        <SidebarModalWrapper className={props?.open ? "open" : "hide"}>
          <StyledSidebar isProfileSidebar ref={profileRef}>
            <StyledSidebartabletHeader isProfileSidebar>
              <Flex gap="8px">
                {isMobile ? (
                  <>
                    <IconComponent
                      Icon={burgerMenu}
                      onClick={openBurgerMenu}
                      pointer
                    />
                    <MainLogo />
                  </>
                ) : null}
              </Flex>
              <Flex gap="8px">
                {profile?.croppedAvatar ? (
                  <Avatar
                    alt="Аватар"
                    src={profile?.croppedAvatar}
                    width={avatarSize}
                    height={avatarSize}
                    onError={() => dispatch(setAvatar({ avatar: null }))}
                  />
                ) : !!avatarWords ? (
                  <DefaultAvatar
                    width={avatarSize + "px"}
                    height={avatarSize + "px"}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <UnselectedText
                      pattern="headline2"
                      fontSize={24}
                      weight={400}
                    >
                      {avatarWords}
                    </UnselectedText>
                  </DefaultAvatar>
                ) : (
                  <></>
                )}
                <IconComponent
                  Icon={closeIcon}
                  onClick={props.onClose}
                  pointer
                />
              </Flex>
            </StyledSidebartabletHeader>
            <Flex padding="0px 16px">
              <StyledMenu isNonActive={!props?.open}>
                <Flex
                  flexDirection="column"
                  justifyContent="flex-start"
                  width="100%"
                  margin="0 0 8px 0"
                >
                  {(hadFirstName || hadLastName) && (
                    <NameText pattern="headline4">{fullName}</NameText>
                  )}
                  {hadEmail && (
                    <Text pattern="caption1" color="dark_gray">
                      {profile?.email}
                    </Text>
                  )}
                </Flex>
                <Flex flexDirection="column">{profileMenuList()}</Flex>
              </StyledMenu>
            </Flex>
          </StyledSidebar>
        </SidebarModalWrapper>
      );
    }
  }
);

ProfilePopup.displayName = "ProfilePopup";

export default ProfilePopup;
