import { useState } from "react";
import { NextPage } from "next";

import { Text, IconComponent } from "@new-ui-kit";
import { StyledItemPropsInterface } from "../types";
import styled from "styled-components";
import { MenuItem } from "react-pro-sidebar";
import { useTouchQuery } from "@helpers/hooks/apaptive";

const StyledMenuItem = styled(MenuItem)<{ selected: boolean }>`
  cursor: pointer;
  border-radius: 10px;
  background-color: ${({ theme, selected }) =>
    selected && theme.lilac} !important;
`;

export const StyledItem: NextPage<StyledItemPropsInterface> = ({
  title,
  selected,
  icon,
  onClick,
}) => {
  const [hovered, setHovered] = useState(false);
  const titleColor = selected ? "purple" : "black";

  const isTouch = useTouchQuery();

  const handleStart = () => {
    if (isTouch) return;
    setHovered(true);
  };

  const handleEnd = () => {
    if (isTouch) return;
    setHovered(false);
  };

  return (
    <StyledMenuItem
      onClick={onClick}
      selected={selected}
      onMouseEnter={handleStart}
      onMouseLeave={handleEnd}
      icon={
        <IconComponent
          Icon={icon}
          hovered={hovered}
          selected={selected}
          isSidebar
        />
      }
    >
      <Text pointer color={titleColor}>
        {title}
      </Text>
    </StyledMenuItem>
  );
};
