import {
  MouseEvent,
  ReactEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import { NextPage } from "next";
import {
  selectCurrentUser,
  selectProfile,
  setAvatar,
  setProfile,
} from "@features/user/user-slice";
import { useMeQuery } from "@features/user/user-api-slice";
import { useAppDispatch, useAppSelector } from "@helpers/hooks/redux";
import { UnselectedText, IconComponent } from "@new-ui-kit";
import ProfilePopup from "./components/ProfilePopup";
import { ProfileStyles } from "./styles";
import { profilePopupStyles } from "./components/ProfilePopup/styles";
import UserIcon from "@assets/profile.svg";
import { useTabletAndBelowMediaQuery } from "@helpers/hooks/apaptive";

const { DefaultAvatar, Avatar } = profilePopupStyles;
const { Container } = ProfileStyles;

const ProfileInLayout: NextPage<{ openBurgerMenu?: () => void }> = ({
  openBurgerMenu,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "profile-popup" : undefined;
  const ref = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const firstName = useAppSelector(selectCurrentUser);
  const profile = useAppSelector((state) => selectProfile(state));
  const ava = useAppSelector((state) => state.user.croppedAvatar);
  const firstNameFirstWord = !!profile?.first_name
    ? profile?.first_name[0]
    : "";
  const isTabletOrMobile = useTabletAndBelowMediaQuery();
  const avatarSize = isTabletOrMobile ? 28 : 40;
  const lastNameFirstWord = !!profile?.last_name ? profile?.last_name[0] : "";
  const avatarWords = `${firstNameFirstWord || ""}${lastNameFirstWord || ""}`;
  const { data: profileData } = useMeQuery({});

  const closeProfilePopup = () => setAnchorEl(null);
  const openProfilePopup = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const changeProfile = () => {
    if (profileData && profileData?.first_name !== firstName) {
      dispatch(setProfile(profileData));
    }
  };
  useEffect(() => {
    changeProfile();
  }, [profileData]);

  useEffect(() => {
    if (profileData?.avatar)
      dispatch(setAvatar({ avatar: profileData.avatar }));
  }, [profileData?.avatar]);

  return (
    <div style={{ cursor: "pointer" }} ref={ref}>
      <button
        onClick={openProfilePopup}
        aria-describedby={id}
        aria-label="Open profile popup"
        id="open-profile-popup"
      >
        <Container>
          {ava ? (
            <Avatar alt="" src={ava} width={avatarSize} height={avatarSize} />
          ) : !!avatarWords ? (
            <DefaultAvatar width={avatarSize + "px"} height={avatarSize + "px"}>
              <UnselectedText pattern="body1" pointer>
                {avatarWords}
              </UnselectedText>
            </DefaultAvatar>
          ) : (
            <IconComponent Icon={UserIcon} pointer />
          )}
        </Container>
      </button>
      <ProfilePopup
        id={id}
        anchorEl={anchorEl}
        ref={ref}
        open={open}
        onClose={closeProfilePopup}
        openBurgerMenu={openBurgerMenu}
      />
    </div>
  );
};

export default ProfileInLayout;
