import { useEffect, useMemo, useRef, useState } from "react";
import { NextPage } from "next";
import { useProSidebar } from "react-pro-sidebar";
import Logo from "@public/logo.svg";
import { StyledItem } from "./components/StyledItem";
import homeIcon from "@assets/sidebarIcons/home.svg";
import agentsIcon from "@assets/sidebarIcons/agents.svg";
import settingsIcon from "@assets/sidebarIcons/settings.svg";
import supportIcon from "@assets/sidebarIcons/support.svg";
import { SidebarStyles } from "./styles";
import { useAppSelector } from "@helpers/hooks/redux";
import { selectUserRole } from "@features/auth/auth-slice";
import { RolesEnum } from "@constants";
import { modalStyles } from "src/ui-kit/Modal/styles";
import { Flex, IconComponent } from "@new-ui-kit";
import burgerMenu from "@assets/sidebarIcons/menu.svg";
import closeIcon from "@assets/close.svg";
import ProfileInLayout from "@components/ProfileInLayout";
import MainLogo from "./components/MainLogo";
import { useDesktopMediaQuery } from "@helpers/hooks/apaptive";
import { useOutsideClick } from "@helpers/hooks/useOutsideClick";
import useSafeRouter from "@helpers/hooks/useSafeRouter";

const {
  StyledLogo,
  StyledSidebar,
  StyledMenu,
  StyledSidebartabletHeader,
  AdaptiveSidebarWrapper,
} = SidebarStyles;
const { SidebarModalWrapper } = modalStyles;

const Sidebar: NextPage = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const { pathname, replace } = useSafeRouter();
  const menuRef = useRef(null);
  const { collapseSidebar } = useProSidebar();
  const pathArray = pathname.split("/");
  const path = useMemo(() => pathArray[1], [pathArray]);
  const role = useAppSelector((state) => selectUserRole(state));
  const isDesktop = useDesktopMediaQuery();

  const goToHome = () => replace("/");
  const goToAgents = () => replace("/agents");
  const goToSupport = () => replace("/support");
  const goToSettings = () => replace("/settings");

  const onMouseEnter = () => setOpenSidebar(true);
  const onMouseLeave = () => setOpenSidebar(false);

  useEffect(() => {
    collapseSidebar(!openSidebar);
  }, [openSidebar]);

  useOutsideClick(menuRef, onMouseLeave);

  const allSidebarList = [
    {
      icon: homeIcon,
      title: "Главная",
      selected: path === "",
      onClick: goToHome,
    },
    {
      icon: agentsIcon,
      title: "Агенты",
      selected: path === "agents",
      onClick: goToAgents,
    },
    {
      icon: supportIcon,
      title: "Помощь",
      selected: path === "support",
      onClick: goToSupport,
    },
    {
      icon: settingsIcon,
      title: "Настройки",
      selected: path === "settings",
      onClick: goToSettings,
    },
  ];
  const baseSidebarList = allSidebarList.filter(
    (item) => item.title !== "Главная"
  );

  const sidebarList =
    role === RolesEnum.ALL_PRIVILEGES ? allSidebarList : baseSidebarList;

  const returnAdaptiveSidebar = () => {
    return (
      <>
        <AdaptiveSidebarWrapper>
          <Flex gap="8px">
            <IconComponent Icon={burgerMenu} onClick={onMouseEnter} pointer />

            <MainLogo />
          </Flex>
          <ProfileInLayout openBurgerMenu={onMouseEnter} />
        </AdaptiveSidebarWrapper>
        <SidebarModalWrapper className={openSidebar ? "open" : "hide"}>
          <StyledSidebar ref={menuRef}>
            <StyledSidebartabletHeader>
              <IconComponent Icon={closeIcon} onClick={onMouseLeave} pointer />
              <MainLogo />
            </StyledSidebartabletHeader>
            <Flex padding="0px 16px">
              <StyledMenu>
                {sidebarList.map(({ ...props }, index) => (
                  <StyledItem key={index} {...props} />
                ))}
              </StyledMenu>
            </Flex>
          </StyledSidebar>
        </SidebarModalWrapper>
      </>
    );
  };

  if (isDesktop) {
    return (
      <StyledSidebar
        width="260px"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <StyledLogo>{openSidebar ? <MainLogo /> : <Logo />}</StyledLogo>

        <StyledMenu>
          {sidebarList.map(({ ...props }, index) => (
            <StyledItem key={index} {...props} />
          ))}
        </StyledMenu>
      </StyledSidebar>
    );
  } else {
    return <>{returnAdaptiveSidebar()}</>;
  }
};

export default Sidebar;
