import { Popover } from "@mui/material";
import { Flex, Text } from "@new-ui-kit";
import Image from "next/image";
import styled from "styled-components";

import { COMMON_BOX_SHADOW } from "@styles/GlobalStyle";

const StyledProfilePopover = styled(Popover)<{
  height?: string;
}>`
  .MuiPopover-paper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #e4e4e7;
    -webkit-box-shadow: ${COMMON_BOX_SHADOW};
    box-shadow: ${COMMON_BOX_SHADOW};
    border-radius: 10px;
    background-color: white;
    height: ${({ height }) => height};
    width: 240px;
    min-height: ${({ height }) => height};
    opacity: 1;
    &::-webkit-scrollbar {
      height: 0px;
      width: 0px;
    }
  }
`;

const DefaultAvatar = styled(Flex)`
  background: #e8e7ff;
  border-radius: 40px;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Avatar = styled(Image)`
  border-radius: 70px;
`;

const NameText = styled(Text)`
  height: 24px;
`;

export const profilePopupStyles = {
  StyledProfilePopover,
  Avatar,
  DefaultAvatar,
  NameText,
};
