import breakpoints from "@styles/breakpoints";
import styled from "styled-components";
import { SidebarStyles } from "@components/Sidebar/styles";

const { ADAPTIVE_SIDEBAR_HEIGHT } = SidebarStyles;

const MainContainer = styled.section`
  display: grid;
  grid-template-columns: auto;
  margin: 0 auto;
  gap: 72px;
  margin-left: 80px;
  height: 100%;
  @media ${breakpoints.tablet} {
    margin-left: 0;
    gap: 16px;
    display: block;
  }
`;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  z-index: auto;
  margin: 0 auto;

  @media (min-width: 1920px) {
    width: 1704px;
  }
  @media (max-width: 1919px) {
    width: 1366px;
  }
  @media (max-width: 1499px) {
    width: 1180px;
  }
  @media ${breakpoints.tablet} {
    width: 690px;
    padding-top: ${ADAPTIVE_SIDEBAR_HEIGHT};
  }
  @media ${breakpoints.mobile} {
    width: 343px;
  }
`;

export const LayoutStyles = { MainContainer, Container };
