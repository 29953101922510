import styled from "styled-components";
import { Text } from "@new-ui-kit";
import { StyledCrumbPropsInterface } from "./types";

const StyledCrumb = styled(Text)<StyledCrumbPropsInterface>`
  div {
    color: ${({ theme, selected }) => selected && theme.text.light_gray};
  }
  &:hover {
    text-decoration: ${({ theme, selected, $start }) =>
      !selected && !$start && `underline solid ${theme.main_violet}`};
  }
`;

export const breadcrumbsStyles = { StyledCrumb };
