import React, { FC } from "react";
import { NextPage } from "next";
import { ProSidebarProvider } from "react-pro-sidebar";
import ProfileInLayout from "@components/ProfileInLayout";
import Sidebar from "@components/Sidebar";
import Breadcrumbs from "@components/Breadcrumbs";
import { Flex } from "@new-ui-kit";
import { LayoutStyles } from "./styles";
import "react-toastify/dist/ReactToastify.min.css";
import { LayoutProps } from "./types";
import { selectUserRole } from "@features/auth/auth-slice";
import {
  useDesktopMediaQuery,
  useTabletAndBelowMediaQuery,
} from "@helpers/hooks/apaptive";
import { RolesEnum } from "@constants";
import { useAppSelector } from "@helpers/hooks/redux";

const { Container, MainContainer } = LayoutStyles;

export const Layout: NextPage<LayoutProps> = ({ children }) => {
  const role = useAppSelector((state) => selectUserRole(state));
  const isDesktop = useDesktopMediaQuery();
  const isTabletOrMobile = useTabletAndBelowMediaQuery();

  const headerComponents = () => {
    if (isDesktop) {
      if (role === RolesEnum.ALL_PRIVILEGES) {
        return (
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            margin="24px 0 0 0"
            alignItems="center"
          >
            <Breadcrumbs />
            <ProfileInLayout />
          </Flex>
        );
      } else {
        return (
          <Flex
            justifyContent="flex-end"
            height="40px"
            width="100%"
            margin="24px 0 0 0"
            alignItems="center"
          >
            <ProfileInLayout />
          </Flex>
        );
      }
    } else if (isTabletOrMobile) {
      return (
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          margin="24px 0 16px 0"
          alignItems="center"
        >
          <Breadcrumbs />
        </Flex>
      );
    }
  };
  return (
    <MainContainer>
      <ProSidebarProvider>
        <Sidebar />
        <Container>
          {isDesktop ? (
            <Flex flexDirection="column" width="100%" height="100%">
              {headerComponents()}
              <>{children}</>
            </Flex>
          ) : isTabletOrMobile ? (
            <Flex flexDirection="column" width="100%" height="100%">
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                margin="16px 0px 0px 0px"
              >
                <Breadcrumbs />
              </Flex>
              <>{children}</>
            </Flex>
          ) : null}
        </Container>
      </ProSidebarProvider>
    </MainContainer>
  );
};

export const withLayout = <T extends Record<string, unknown>>(
  Component: FC<T>
) => {
  return function withLayoutComponent(props: T): JSX.Element {
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    );
  };
};
