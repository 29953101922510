import { useMemo } from "react";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { pathFormatter } from "@utils";
import { Flex, Text } from "@new-ui-kit";
import { breadcrumbsStyles } from "./styles";

const { StyledCrumb } = breadcrumbsStyles;

const Breadcrumbs: NextPage = () => {
  const { pathname, push, query } = useRouter();
  const pathArr = useMemo(
    () => pathname.split("/").filter((item) => item !== ""),
    [pathname]
  );
  const isStart = pathArr.length === 0;

  const goToPath = (item: string, selected: boolean, isHome?: boolean) =>
    !selected && isHome ? push(`/`) : !selected && push(`/${item}`);

  return (
    <Flex flexDirection="row" justifyContent="flex-start">
      <StyledCrumb $start={isStart}>
        <Text
          pattern="caption1"
          pointer={pathArr.length !== 0}
          onClick={() => goToPath("/", isStart, true)}
        >
          Главная
        </Text>
      </StyledCrumb>
      {pathArr?.map((item, index) => {
        return (
          <Flex flexDirection="row" key={item}>
            <Text pattern="caption1">&nbsp;/&nbsp;</Text>
            <StyledCrumb selected={pathArr.length - 1 === index}>
              <Text
                pattern="caption1"
                pointer={pathArr.length - 1 !== index}
                onClick={() => goToPath(item, pathArr.length - 1 === index)}
              >
                {pathFormatter(item, query)}
              </Text>
            </StyledCrumb>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default Breadcrumbs;
