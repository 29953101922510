import styled from "styled-components";
import breakpoints from "@styles/breakpoints";
import { Sidebar as _Sidebar, Menu } from "react-pro-sidebar";

const ADAPTIVE_SIDEBAR_HEIGHT = "48px";

const StyledLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  opacity: 1;
  margin-bottom: 24px;
`;

const StyledSidebar = styled(_Sidebar)<{
  width?: string;
  isProfileSidebar?: boolean;
}>`
  z-index: 800;
  padding: 24px 15px 24px 16px;
  position: fixed !important;
  overflow: hidden !important;
  top: 0 !important;
  left: ${({ isProfileSidebar }) =>
    isProfileSidebar ? "auto" : "0"} !important;
  right: ${({ isProfileSidebar }) =>
    isProfileSidebar ? "0" : "auto"} !important;
  height: 100%;
  background-color: ${({ theme }) => theme.bg.cards}!important;
  border-right: ${({ theme }) => ` 1px solid${theme.border.sidebar}`}!important;
  overflow-y: hidden;
  @media ${breakpoints.tablet} {
    width: 375px !important;
  }
  @media ${breakpoints.mobile} {
    width: 100% !important;
  }
  .ps-sidebar-container {
    overflow-y: hidden;
    background-color: ${({ theme }) => theme.bg.cards}!important;
  }
  .ps-sidebar-root {
    position: fixed !important;
    overflow: hidden !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
  }
  .ps-collapsed {
    width: 80px;
  }
  .ps-menu-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 11px;
    padding-right: 0 !important;
    padding-left: 0 !important;

    transition: 0.2s ease-out;
    height: 48px !important;
    padding: 12px !important;
    &:hover {
      transition: 0.2s ease-out;
      background: #f3f3ff !important;
      border-radius: 10px !important;
    }
    &:active {
      background: #e8e7ff !important;
    }
  }
  .ps-menu-root {
    margin-right: 0 !important;
  }
  .ps-menu-icon {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    margin-right: 0 !important;
  }
  .ps-menu-label {
    width: 164px !important;
  }

  @media (max-width: 1279px) {
    padding: 0px;
  }
`;

const StyledSidebartabletHeader = styled.div<{ isProfileSidebar?: boolean }>`
  border-bottom: 1px solid #e4e4e7;
  display: flex;
  gap: 8px;
  height: 48px;
  align-items: center;
  width: 100%;
  padding: 0px 16px;
  margin-bottom: 24px;
  justify-content: ${({ isProfileSidebar }) =>
    isProfileSidebar ? "space-between" : "flex-start"};
`;

const AdaptiveSidebarWrapper = styled.div`
  display: flex;
  width: 100%;
  height: ${ADAPTIVE_SIDEBAR_HEIGHT};
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  border-bottom: 1px solid var(--colors-stroke-default, #e4e4e7);
  background: var(--colors-bg-cards, #fff);
  position: fixed;
  z-index: 4;
`;

const StyledMenu = styled(Menu)<{ isNonActive?: boolean }>`
  width: 100%;
  display: ${({ isNonActive }) => (isNonActive ? "none" : "block")};

  ul {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    gap: 16px !important;
  }
`;

export const SidebarStyles = {
  StyledLogo,
  StyledSidebar,
  StyledMenu,
  StyledSidebartabletHeader,
  AdaptiveSidebarWrapper,
  ADAPTIVE_SIDEBAR_HEIGHT,
};
